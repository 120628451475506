import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Types of Render`}</h1>
    <p>{`Due to major developments in the industry over recent years, we can offer a wide range of finishes and treatments that will enhance and improve the exterior of your property.`}</p>
    <p>{`No matter what style of property you own we have the expertise in external finishing.`}</p>
    <p>{`There are a number of factors to consider when choosing a render system for your property, such as the existing substrate, age of the property, desired colour and finish.`}</p>
    <p>{`Choose a modern system for a exterior render that will increase the aesthetics of your property whilst providing a flexible shell that protects from the elements.`}</p>
    <p>{`Consider a traditional system to go for a classic look or to reinstate an exterior finish, including moldings or features, returning them to their original condition.`}</p>
    <p>{`If you are unsure what render type would be suitable, get in touch and we’ll help and guide you towards making the right decision for your property.`}</p>
    <p>{`Below is a brief description of each of the rendering methods we offer, for a detailed introduction to each type of render accompanied with photos click the ‘Learn more’ link.`}</p>
    <h2>{`Special Finishes`}</h2>
    <p>{`Modern advancements have allowed render techniques to grow beyond the more traditional finishes. We can offer a wide range of cutting edge products to give you the finish you desire. Whether that be an Acrylic render, Scratch render or one of our other finishes.`}</p>
    <h3><a parentName="h3" {...{
        "href": "/render-types/polymer-render/"
      }}>{`Polymer Render`}</a></h3>
    <p>{`Polymer render is a cement based system with specially selected polymers added to the mix. These polymers make the finished coat strong yet flexible, allowing them to be used on a number of substrates. `}<a parentName="p" {...{
        "href": "/render-types/polymer-render/"
      }}>{`Learn more`}</a></p>
    <h3><a parentName="h3" {...{
        "href": "/render-types/acrylic-render/"
      }}>{`Acrylic Render`}</a></h3>
    <p>{`Acrylic render is a finishing coat for render, containing acrylic aggregates to display an attractive finish. It is applied to new and existing render to seal the substrate layer and enhance the appearance of the elevation. `}<a parentName="p" {...{
        "href": "/render-types/acrylic-render/"
      }}>{`Learn more`}</a></p>
    <h3><a parentName="h3" {...{
        "href": "/render-types/scratch-render/"
      }}>{`Scratch Render`}</a></h3>
    <p>{`Scratch render is a cement based render with a surface that looks similar to weathered stone when finished. It is applied in such a manner that the elevation of a property is made to look plaster flat. The surface of the render is then ‘scratched’ with a nail float. `}<a parentName="p" {...{
        "href": "/render-types/scratch-render/"
      }}>{`Learn more`}</a></p>
    <h3><a parentName="h3" {...{
        "href": "/render-types/brick-effect-render/"
      }}>{`Brick Effect Render`}</a></h3>
    <p>{`Brick Effect Render is a versatile alternative to traditional brickwork and is ideal for use on projects where traditional new brick-work is impractical. It is applied in two coats. The top coat is then cut through to expose the base coat ‘mortar’ layer, thus creating the brick effect finish. `}<a parentName="p" {...{
        "href": "/render-types/brick-effect-render/"
      }}>{`Learn more`}</a></p>
    <h2>{`Traditional Finishes`}</h2>
    <p>{`Traditional finishes range from Lime Mortar render and Sand & Cement to Dry Dash and Pebble Dash. Whatever traditional render finish you are looking for, we have the expertise to meet your expectations.`}</p>
    <h3><a parentName="h3" {...{
        "href": "/render-types/pebble-dash/"
      }}>{`Pebble Dash`}</a></h3>
    <p>{`Pebble Dash is a decorative exterior masonry coating applied to a wall using a wet mortar with pebbles (or spar) cast onto it. `}<a parentName="p" {...{
        "href": "/render-types/pebble-dash/"
      }}>{`Learn more`}</a></p>
    <h3><a parentName="h3" {...{
        "href": "/render-types/lime-render/"
      }}>{`Lime Render`}</a></h3>
    <p>{`Lime render is by far the most breathable render available, although relatively soft, lime render expands and contracts with the building making it particularly suited to older buildings or wooden framed buildings. `}<a parentName="p" {...{
        "href": "/render-types/lime-render/"
      }}>{`Learn more`}</a></p>
    <h3><a parentName="h3" {...{
        "href": "/render-types/sand-and-cement/"
      }}>{`Sand & Cement`}</a></h3>
    <p>{`A sealed or painted cement render can help a building in by helping prevent penetrating damp. Cement renders are generally mixed using ordinary Portland cement and is usually much harder and stronger than lime render. `}<a parentName="p" {...{
        "href": "/render-types/sand-and-cement/"
      }}>{`Learn more`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      